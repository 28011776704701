import { NavbarItem } from "@/interfaces/navbar-item";
import { RouterPathName } from "@/enums/router-path-name.enum";

/** Mocked top navigation items data */
export const topNavbarItems: NavbarItem[] = [
  { routeName: RouterPathName.Services, content: "Services" },
  { routeName: RouterPathName.CaseStudies, content: "Case studies" },
  {
    routeName: RouterPathName.Estimate,
    content: "Estimate your project",
    isCTA: true
  }
];
