import { Vue } from "vue-property-decorator";
import Component from "vue-class-component";

import EventBus from "@/event-bus";

import { AppOverlayOptions } from "@/plugins/overlay/interfaces/app-overlay-options";
import { AppOverlayCloseEventData } from "@/plugins/overlay/interfaces/app-overlay-close-event-data";

import { OverlayEvent } from "@/plugins/overlay/enums/overlay-event.enum";
import { OverlayCloseType } from "@/plugins/overlay/enums/overlay-close-type.enum";

@Component
export default class AppOverlayMixin extends Vue {
  /**
   * Opens overlay
   * @param options Optional configuration options
   */
  openOverlay(options?: AppOverlayOptions): void {
    EventBus.$emit(OverlayEvent.OverlayOpenRequested, options);
  }

  /** Closes overlay */
  closeOverlay(): void {
    EventBus.$emit(OverlayEvent.OverlayCloseRequested, {
      closeType: OverlayCloseType.External
    } as AppOverlayCloseEventData);
  }
}
