

































import { Watch } from "vue-property-decorator";
import Component, { mixins } from "vue-class-component";
import { Mutation, State } from "vuex-class";

import EventBus from "@/event-bus";

import AppOverlayMixin from "@/plugins/overlay/mixins/app-overlay.mixin";

import { topNavbarItems } from "@/mock/top-navbar-items";

import { NavbarItem } from "@/interfaces/navbar-item";
import { AppOverlayCloseEventData } from "@/plugins/overlay/interfaces/app-overlay-close-event-data";

import { OverlayEvent } from "@/plugins/overlay/enums/overlay-event.enum";
import { OverlayCloseType } from "@/plugins/overlay/enums/overlay-close-type.enum";

@Component({
  components: {
    SlideDownTransition: () =>
      import(
        /* webpackChunkName: 'app-top-navbar-slide-down-transition' */ "@/transitions/SlideDownTransition.vue"
      ),
    AppLogo: () =>
      import(
        /* webpackChunkName: 'app-top-navbar-app-logo' */ "@/components/ui/AppLogo.vue"
      )
  }
})
export default class AppTopNavbar extends mixins(AppOverlayMixin) {
  @Watch("isOpened", { immediate: true }) private isOpenedUpdated(): void {
    if (this.isOpened) {
      this.openOverlay({
        breakpoint: "sm"
      });
    } else {
      this.closeOverlay();
    }
  }

  @Mutation("ui/toggleTopNavbar") toggle!: () => void;
  @Mutation("ui/closeTopNavbar") close!: () => void;
  @State(state => state.ui.topNavbarOpened) isOpened!: boolean;

  items: NavbarItem[] = topNavbarItems;

  created(): void {
    EventBus.$on(
      OverlayEvent.OverlayCloseRequested,
      (closeEventData: AppOverlayCloseEventData) => {
        if (closeEventData.closeType === OverlayCloseType.Internal) {
          this.close();
        }
      }
    );
  }
}
